export const HEADER_CONFIG = {
    headerTitle: "Nasze domy",
};

export const DOUBLE_PHOTO_SECTION_CONFIG = [
    {
        id: 1,
        sideTitle: "Ignaś",
        author: "Marta Pudlis",
        authorPosition: "Koordynator",
        content: [
            {
                id: 1,
                text: 'W placówce "IGNAŚ" przebywa obecnie 16 wychowanków w wieku od 5 do 17 lat. Nasi wychowankowie zdobywają swoje pierwsze sukcesy w przedszkolu, poszerzają wiedzę w szkole podstawowej i przygotowują się do dorosłości w szkole średniej. Dokładamy wszelkich starań, aby wspierać naukę dzieci – codziennie organizujemy wspólne odrabianki, czytanie książek, wychodzimy naprzeciw trudnościom – dzieci uczęszczają na korepetycje, zajęcia wczesnego wspomagania i integracji sensorycznej.',
            },
            {
                id: 2,
                text: 'Nasi wychowankowie mają różnorodne zainteresowania, głównie harcerstwo i piłka nożna. Dzieci należą do 11 gromady Zuchowej "Rajskie Ptaki" i  11 Drużyny Harcerskiej „Julin”. Lubią aktywnie spędzać swój wolny czas, dlatego też organizujemy wyjścia na spacery, jeździmy na rowerowe wycieczki, chodzimy nad położoną w naszej okolicy rzekę Liwiec, gdzie odpoczywamy na piaszczystej plaży i kąpiemy się. W wolnym czasie korzystamy również z terenu wokół naszego domu, gdzie mamy do dyspozycji boisko, trampolinę, huśtawki oraz plac zabaw z siłownią. Nasze dzieciaki lubią zorganizowany czas wolny przy współpracy z wspierającymi nasze działania firmami i fundacjami. Chętnie integrują się wyjeżdżając na obozy w okresie ferii zimowych i wakacji.',
            },
            {
                id: 3,
                text: 'Codziennie przygotowujemy naszych wychowanków do samodzielnego funkcjonowania. Nasz dzień to nie tylko nauka i zabawa, ale również przygotowywanie posiłków i porządki w domu. Rozwijamy umiejętności kulinarne dzieciaków – przygotowujemy sałatki, pieczemy ciasta, ciasteczka, organizujemy warsztaty z pieczenia i zdobienia pierników, robienia pizzy.',
            },
            {
                id: 4,
                text: 'Staramy się dbać o tradycje rodzinne, szczególnie w okresie świątecznym. Wspólnie siadamy do kolacji Wigilijnej i dzielimy się opłatkiem, przygotowujemy tradycyjne, świąteczne potrawy. Odwiedza nas również Święty Mikołaj. W okresie Świąt Wielkanocnych również dbamy o świąteczną atmosferę, w sobotę święcimy pokarmy wielkanocne, które wspólnie spożywamy podczas śniadania. Obchodzimy również inne, ważne święta np. Dzień Papieski, Dzień Dziecka, Święta narodowe i wiele innych. Świętujemy również urodziny naszych wychowanków organizując przyjęcie dla grupy oraz tort.',
            },
            {
                id: 5,
                text: "Staramy się wspierać naszych wychowanków w kontaktach rodzinnych, czuwamy nad prawidłowym przebiegiem odwiedzin oraz pracujemy z rodzinami nad poprawą ich sytuacji. Dbamy również o zdrowie naszych dzieci. Na bieżąco monitorujemy stan zdrowia, korzystamy z opieki poradni specjalistycznych.",
            },
            {
                id: 6,
                text: "Uwieczniamy ważne momenty z życia naszych dzieciaków na zdjęciach. W pracy wychowawczej mamy wiele wspólnych wspomnień jak i planów do zrealizowania z naszymi wychowankami. Każde z naszych dzieciaków posiada swój bagaż życiowy, ale każde jest wyjątkowe i pozostawia w naszej pamięci wspomnienia.",
            },
        ],
    },
    {
        id: 2,
        sideTitle: "Helenka",
        author: "Mariola Kurowska",
        authorPosition: "Koordynator",
        content: [
            {
                id: 1,
                text: "W Placówce Opiekuńczo Wychowawczej „Helenka” wychowankowie są w wieku od 4 do 18 r. ż., przeważająca większość to dzieci w okresie dorastania. Dzieci mają przyjęty ogólny plan dnia, dzięki któremu łatwiej im się zorganizować, aby wszelkie obowiązki były wykonane na czas. W dni powszednie wychowankowie realizują obowiązek szkolny w szkołach odpowiednich do wieku i możliwości, natomiast popołudnia spędzają z grupą wychowawczą. Czas wolny staramy się organizować tak, aby dzieci aktywnie go spędzały, rozwijając przy tym swoje zainteresowania. Wychowankowie wykazujący chęć własnego rozwoju mają możliwość uczęszczania na szereg dodatkowych zajęć np. dodatkowe lekcje z j. angielskiego, matematyki, treningi z box-u, lekcje tańca, lekcje jazdy konnej. Dbając o świadomość i bezpieczeństwo dzieci w różnych sytuacjach organizuje się profilaktyczne spotkania z funkcjonariuszami policji i ze strażakami. Podopieczni placówki wolne wieczory chętnie spędzają oglądając telewizję.",
            },
            {
                id: 2,
                text: "Zadaniem kadry jest przede wszystkim dbanie o to, aby dzieci czuły się w placówce jak w swoim drugim domu, gdzie mają zapewnione wszelkie potrzeby. Zadbany teren placówki i czystość w domu jest owocem pracy i zaangażowania naszych wychowanków i wychowawców oraz pozostałych pracowników.",
            },
            {
                id: 3,
                text: "Praca ze starszymi wychowankami opiera się na uczeniu ich samodzielności i ponoszenia odpowiedzialności za własne decyzje, działania. Wychowankowie mają możliwość samodzielnego wyjścia poza teren placówki i spotykania się ze znajomymi co wpływa pozytywnie na rozwój samodzielności i zaradności społecznej.",
            },
            {
                id: 4,
                text: "Podejmujemy również współpracę z organizacjami z zewnątrz, których przedstawiciele często nas odwiedzają. Podczas spotkań wspólnie realizujemy przygotowane przez nich scenariusze zajęć np. zajęcia sportowe, zajęcia ogólnorozwojowe, zajęcia rozwijające zainteresowania podopiecznych.",
            },
        ],
    },
];
