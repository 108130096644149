import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import PageLayout from "../components/layout/PageLayout";
import SubsiteHeader from '../components/molecules/SubsiteHeader';
import { DoublePhotoSection } from '../components/sections';

import { sectionConfigMapper } from '../utils/helpers';

import {
    HEADER_CONFIG,
    DOUBLE_PHOTO_SECTION_CONFIG,
} from '../content/our_homes_content_config';
import Seo from '../components/seo';

const OurHousesPage = ({ location }) => {
    const { headerTitle } = HEADER_CONFIG;

    const photoItemsQuery = useStaticQuery(
        graphql`
            {
                photoItemImage1: file(relativePath: { eq: "DD_nasze_domy_ignas.png" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                    }
                }
                photoItemImage2: file(relativePath: { eq: "DD_nasze_domy_helenka.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                    }
                }
            }
        `
    );

    const photoItemsImage1 = getImage(photoItemsQuery.photoItemImage1);
    const photoItemsImage2 = getImage(photoItemsQuery.photoItemImage2);

    const photoItemsImages = [
        photoItemsImage1,
        photoItemsImage2,
    ];

    const mappedSectionConfig = sectionConfigMapper(DOUBLE_PHOTO_SECTION_CONFIG, photoItemsImages);
    
    return (
        <>
            <Seo
                title="Nasze domy | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Placówki Opiekuńczo-Wychowawcze Ignaś i Helenka w Kaliskach dysponują dwoma nowoczesnymi domami dla dzieci oraz przestronnym terenem przystosowanym do bezpiecznego spędzania czasu."
            />

            <PageLayout location={ location }>
                <SubsiteHeader title={ headerTitle } />

                <DoublePhotoSection config={ mappedSectionConfig } />
            </PageLayout>
        </>
    );
};
 
export default OurHousesPage;
